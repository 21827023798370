@import "../assets/styles/fonts.scss";

* {
    outline: none;
    box-sizing: border-box;
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #7371dc;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #f7ba2e;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #9d9c9c;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-gray-light: #f3f4f6;
    --bs-primary: #774200;
    --bs-secondary: #ffa233;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 4, 1, 75;
    --bs-secondary-rgb: 252, 197, 96;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-rgb: 33, 37, 41;
    --bs-font-sans-serif: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-sans-serifa: 'Plus Jakarta Sans', sans-serif;
    --bs-font-sans-serifar: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-familyr: var(--bs-font-sans-serifr);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-font-weighta: 500;
    --bs-body-font-weightb: 600;
    --bs-body-line-height: 1.2;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
}

html,
body,
#root {
    font-family: var(--bs-font-sans-serif);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
    background-color: #181b1c;
}

body {
    margin: 0;
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

input[type="number"] {
    -moz-appearance: textfield !important;
}

p {
    margin: 0;
}

.web3modal-modal-lightbox {
    z-index: 10 !important;
    background: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(100px) !important;

    .web3modal-modal-card {
        background: #181b1c;
        backdrop-filter: blur(100px);
        border-radius: 10px;

        .web3modal-provider-wrapper {
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 0;
            padding: 0;

            .web3modal-provider-container {
                background-color: rgb(0, 0, 0, 0);
                border-radius: 0;

                &:hover {
                    background-color: rgb(0, 0, 0, 0.2);
                }

                .web3modal-provider-name {
                    font-family: Montserrat SemiBold;
                    font-weight: 600;
                    font-size: 24px;
                    text-align: center;
                    color: #ffffff;
                }

                .web3modal-provider-description {
                    font-family: Montserrat Light;
                    font-weight: 300;
                    font-size: 14px;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }
}

.walletconnect-qrcode__base {
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background: rgba(0, 0, 0, 0.2) !important;
        backdrop-filter: blur(100px) !important;
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: rgba(0, 0, 0, 0.8);
    }

    .walletconnect-modal__base {
        border-radius: 10px;
        padding: 30px 20px 20px 20px;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: #181b1c;
            backdrop-filter: blur(100px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }

        .walletconnect-modal__header {
            .walletconnect-modal__headerLogo {
                display: none;
            }

            p {
                margin-left: 24px;

                font-family: Montserrat SemiBold;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: #ffffff;
            }

            .walletconnect-modal__close__wrapper {
                background: none;
                right: 23px;

                .walletconnect-modal__close__icon {
                    .walletconnect-modal__close__line1 {
                        border: 1px solid rgb(255, 255, 255);
                    }
                    .walletconnect-modal__close__line2 {
                        border: 1px solid rgb(255, 255, 255);
                    }
                }
            }
        }

        .walletconnect-modal__mobile__toggle {
            margin-bottom: 20px;
            background: none;
            width: 60%;

            .walletconnect-modal__mobile__toggle_selector {
                height: 1px;
                background: #ffffff;
                top: 34px;
            }

            a {
                font-family: Montserrat SemiBold;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #ffffff;
                cursor: pointer;

                &:last-child {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }

        .walletconnect-modal__mobile__toggle.right__selected {
            a {
                &:nth-child(2) {
                    color: rgba(255, 255, 255, 0.6);
                }

                &:last-child {
                    color: #ffffff;
                }
            }
        }

        .walletconnect-qrcode__text {
            font-family: Montserrat Light;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
        }

        .walletconnect-qrcode__image {
            background: #ffffff;
            border-radius: 10px;
            padding: 13px;
        }

        .walletconnect-modal__footer {
            font-family: Montserrat Light;
            font-weight: 300;
            font-size: 14px;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
        }

        .walletconnect-modal__base__row {
            h3 {
                font-family: Montserrat SemiBold;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: #ffffff;
            }
        }
    }
}
