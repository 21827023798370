.view-base-root {
    // height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    // background: #121415;

    .cubes-top {
        opacity: 0.3;
        position: absolute;
        top: 0;
        max-width: 50%;
        left: 230px;
        margin: 0;
        z-index: 0;

        img {
            width: 100%;
        }
    }

    .cubes-bottom {
        opacity: 0.3;
        position: absolute;
        top: 0;
        right: 0;
        max-width: 50%;
        margin: 0;
        z-index: 0;

        img {
            width: 100%;
        }
    }
}
