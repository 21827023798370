.liquidity-banner-root {
    background: linear-gradient(180deg, rgba(61, 105, 101, 0.15) 0%, rgba(143, 222, 224, 0.15) 100%);
    backdrop-filter: blur(150px);
    border-radius: 20px;
    padding: 11px 23px;
    width: 89%;
    max-width: 833px;
    margin: 0px auto 20px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .liquidity-banner-text-conteiner {
        text-align: center;
        margin: 0px auto;
        padding-left: 30px;

        .liquidity-banner-text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;

            a {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #ffffff;
            }
        }

        .liquidity-banner-text.small {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
        }

        .liquidity-banner-text.upper {
            text-transform: uppercase;
        }
    }

    .liquidity-banner-close-wrap {
        cursor: pointer;
        margin-left: 30px;
        margin-top: 5px;

        svg {
            width: 15px;
        }
    }

    .liquidity-banner-left-circle {
        position: absolute;
        left: 30px;
        bottom: -15px;
    }

    .liquidity-banner-left-gif {
        position: absolute;
        left: 50px;
        bottom: -4px;

        img {
            width: 20px;
            height: auto;
        }
    }

    .liquidity-banner-right-gif {
        position: absolute;
        right: 50px;
        bottom: -4px;

        img {
            width: 20px;
            height: auto;
        }
    }

    .liquidity-banner-right-circle {
        position: absolute;
        right: 30px;
        top: -15px;
        transform: rotate(180deg);
        z-index: -1;
    }
}

@media screen and (max-width: 630px) {
    .liquidity-banner-root {
        .liquidity-banner-left-circle,
        .liquidity-banner-right-circle {
            display: none;
        }
    }
}
