:root {
    --bs-font-sans-serifar: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: white;
    font-family: "Age", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji" !important;
}
body {
    --bs-font-sans-serifar: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    /* background-image: url('../../assets/images/'); */

    background: #000 ;
    color: #fff !important;
    overflow: hidden !important;
}
.fa {
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-weight: var(--fa-style, 900);
}

.fa,
.fas,
.fa-solid {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}
.stake-viewNew {
    position: relative;
    z-index: 1;
    padding: 2%;
    padding-top: 0%;
}
.dahsboard-sub-title {
    font-family: var(--bs-font-sans-serifar);
    margin-bottom: 25px;
    font-size: 24px;
    /* display: inline-block !important; */
}
.staking-title {
    margin-bottom: 25px;
    margin-top: 50px;
    justify-content: space-between;
}
.staking-title h2 {
    font-family: var(--bs-font-sans-serifa);
    font-size: 32px;
    margin: 0;
}
.feature-box {
    padding: 20px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    padding-left: 140px;
    position: relative;
    margin: 35px 0;
}
.feature-box img {
    height: 140px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}
.feature-box h3 {
    font-family: var(--bs-font-sans-serifa);
    text-transform: uppercase;
    font-size: 24px;
    margin: 0;
    background: #ffab5b;
    background: #65d013;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.staking-title .feature-box {
    margin: 0;
}
.btn {
    display: inline-block;
    font-family: var(--bs-font-sans-serifar);
    font-weight: 700;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 0;
    padding: 14px 25px;
    font-size: 14px;
    border-radius: 50px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.staking-title .feature-box img {
    height: 90px;
}
.admin-staking-plans {
    margin: 30px 0;
}
.plan-column {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    margin-bottom: 30px;
}
.plan-column:last-child {
    margin-bottom: 0;
}
.staking-head {
    display: block;
    /* justify-content: space-between; */
}
.stake-icon {
    overflow: hidden;
    width: 120px;
    height: 120px;
    line-height: 100px;
    text-align: center;
    position: relative;
    /* background: rgba(255, 171, 91, 0.1); */
}
.stake-icon img {
    position: absolute;
    max-width: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, c -50%);
}
@media (max-width:1550px) {
    
    .stake-icon {
        width: 80px;
        height: 80px;
    }
    .stake-icon img {
        max-width: 80px;
    }
    .stake-titles h3 {
        font-family: var(--bs-font-sans-serifar);
        color: #6cebcd;
        text-transform: uppercase;
        font-size: 15px!important;
        margin-bottom: 10px;
    }
}
@media (max-width:1278px) {
    
    .stake-icon {
        width: 120px;
        height: 120px;
    }
    .stake-icon img {
        max-width: 120px;
    }
    .stake-titles h3 {
        font-size: 25px!important;
        margin-bottom: 10px;
    }
}
.stake-titles {
    margin-left: 15px;
}
.stake-titles h3 {
    font-family: var(--bs-font-sans-serifar);
    color: #6cebcd;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 10px;
}
.stake-titles h4 {
    margin-bottom: 0;
    font-size: 18px;
    font-family: var(--bs-font-sans-serifar);
}
.stake-titles h4 {
    color: #ffab5b;
}
.stake-titles h4 b {
    font-weight: 400;
    color: #fff;
}
.plan-details {
    border: none;
    padding-top: 0;
    list-style: none;
    padding: 0;
}
.plan-details > li {
    margin-bottom: 10px;
}
.plan-details > li:last-child {
    margin-right: 0;
}
.plan-con {
    display: flex;
    justify-content: space-between;
}
.plan-con span {
    padding: 0;
    background: none;
    font-weight: 700;
    color: #ffab5b;
}
.plan-con h4 {
    margin-top: 7px;
    font-size: 14px;
    opacity: 0.8;
}
.active .plan-con {
    padding: 12px 15px;
    background: rgba(183, 227, 10, 0.1);
    border: 1px solid #1cbaed;
    border-radius: 10px;
}
.active .plan-con span {
    color: #1cbaed;
}
.staking-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.staking-titles > div {
    opacity: 0.6;
    font-size: 12px;
}
.staking-field {
    /* width: 400px; */
    margin-left: auto;
}
.staking-form {
    position: relative;
}
.staking-form input {
    padding-right: 80px;
    line-height: 60px;
    display: block;
    width: 100%;
    border: none;
    outline: none;
    font-weight: 700;
    font-size: 24px;
    background: rgba(0, 0, 0, 0.4);
    text-align: right;
    border-radius: 6px;
    color: #fff;
}
.staking-form .token-ticker {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    font-size: 11px;
}
.staking-form .token-ticker img {
    max-width: 36px;
}
.amount-percentage {
    margin-top: 5px;
    margin-left: -2px;
    margin-right: -2px;
}
.amount-percentage label {
    width: 25%;
    float: left;
    text-align: center;
    cursor: pointer;
    padding: 5px 2px;
}
.amount-percentage label span {
    background: rgba(183, 227, 10, 0.2);
    border: 1px solid #1cbaed;
    color: #d8f664;
    display: block;
    font-size: 11px;
    line-height: 24px;
    border-radius: 4px;
}
.admin-staking-plans .button-set {
    margin-top: 5px;
}
.admin-staking-plans .button-set .btn {
    border-radius: 4px;
}
.admin-staking-plans .button-set.full .btn {
    width: 100%;
}
.admin-staking-plans .button-set.half .btn {
    width: 47%;
    margin-right: 4%;
}
.admin-staking-plans .button-set.half .btn:last-child {
    margin-right: 0;
}
.btn-green {
    background-color: #1cbaed !important;
    color: #2f2f2f !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}
.btn-primary {
    color: #fff;
    border: 0;
    background: #6cebcd;
    background: -moz-#1cbaed;
    background: -webkit-#1cbaed;
    background: #1cbaed !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6cebcd', endColorstr='#ffab5b',GradientType=1 );
}
.card {
    padding: 25px;
    padding-top: 5px !important;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    position: relative;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}
.card.gradient:after {
    opacity: 0;
    visibility: hidden;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 10px;
    background: -webkit-linear-gradient(-45deg, rgba(183, 227, 10, 0.1) 0%, rgba(251, 101, 45, 0) 100%);
    background: linear-gradient(135deg, rgba(183, 227, 10, 0.1) 0%, rgba(251, 101, 45, 0) 100%);
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}
.card.gradient:hover:after {
    opacity: 1;
    visibility: visible;
}
.card-dark {
    padding: 25px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    position: relative;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}
.card > h3 {
    /* margin-top: 5px !important; */
    font-family: var(--bs-font-sans-serifa);
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    background: #ffab5b;
    background: #65d013;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.card > h3.small {
    font-size: 18px;
}
.disconnect {
    margin-top: 10px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}
.branding-header h4 {
    color: #fff;
    font-size: 15px;
    margin: 0px;
    margin-top: 7px;
    font-weight: 500;
}
.fig {
    font-family: var(--bs-font-sans-serifar);
    font-size: 32px;
}
.fig > div {
    position: relative;
}
.fig .btn.claim {
    position: absolute;
    bottom: -19px;
    right: 0%;
}
.fig span {
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
}
.fig span img {
    height: 34px;
    vertical-align: text-bottom;
}
.card > .icon {
    background: rgba(183, 227, 10, 0.1);
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    position: absolute;
    right: 20px;
    top: calc(50% - 40px);
    border-radius: 100%;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}
.card > .icon1 {
    background: rgba(183, 227, 10, 0.1);
    width: 50px;
    height: 50px;
    line-height: 80px;
    text-align: center;
    position: absolute;
    right: 20px;
    top: calc(50% - 40px);
    border-radius: 100%;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}
.card.gradient:hover .icon {
    background: rgba(183, 227, 10, 0.2);
}
.card > .icon img {
    width: 40px;
}
.card > .icon1 img {
    width: 40px;
}
.mt-30 {
    margin-top: 30px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.congrats {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}
.congrats img {
    max-width: 120px;
}
.unclaimed-box {
    padding-left: 130px;
}
.unclaimed-box h3 {
    font-family: var(--bs-font-sans-serifa);
    font-weight: normal;
    font-size: 32px;
    margin-bottom: 10px;
    background: #ffab5b;
    background: #65d013;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.un-title p {
    font-size: 18px;
    color: #fff;
}
.un-title p span {
    font-weight: 700;
    color: #6cebcd;
}
.unclaimed-box .button-set {
    margin-left: auto;
}
.text-secondary {
    color: #6cebcd !important;
}
.text-primary {
    color: #1cbaed !important;
}
.card > .icon img {
    margin-top: 20px;
}
.card > .icon1 img {
    margin-top: 5px;
}
.locked-overlay {
    background-color: #0e091e66;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 20px 20px 20px 20px;
    text-align: center;
}
.referal-box {
    position: relative;
}
.referal-title {
    margin-bottom: 25px;
}
.text-center {
    text-align: center !important;
}
.ref-container {
    /* padding-right: 150px; */
    position: relative;
}
.ref-container img {
    height: calc(100% + 20px);
    position: absolute;
    right: -80px;
    bottom: 0;
}

.blur {
    filter: blur(7px);
}
.ref-container h2 {
    font-family: var(--bs-font-sans-serifa);
    font-size: 40px;
    margin-bottom: 10px;
}
.bottle-green {
    color: #6cebcd;
}
.green {
    color: #1cbaed;
}
.link-box {
    border-radius: 10px;
    background: rgba(183, 227, 10, 0.1);
    border: 1px solid #1cbaed;
    color: #d8f664;
    overflow: hidden;
}
.link-title {
    padding: 10px 15px;
    background: #1cbaed;
    color: #2f2f2f;
    font-weight: 500;
    margin-right: 20px;
    border-radius: 0 10px 10px 0;
}

.r-link {
    font-size: 18px;
}
.btn.small {
    padding: 5px 20px;
    font-size: 12px;
}
.lockIcon {
    height: 24%;
    width: 24%;
    height: unset !important;
    position: unset !important;
    right: unset !important;
}
.btn-secondary {
    color: #000;
    background-color: #fff;
    border: 0;
}
.btn-green:focus,
.btn-green:hover {
    background-color: #fff !important;
    color: #2f2f2f;
}
.btn-primary:hover {
    color: #fff;
    border: 0;
    background: #ffab5b;
    background: -moz-#119dcc;
    background: -webkit-#119dcc;
    background: #119dcc !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffab5b', endColorstr='#6cebcd',GradientType=1 );
}
.boxpadding {
    padding-left: 176px;
    padding-right: 176px;
}
.admin-title {
    padding: 20px 40px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
}
.verified-contract {
    position: relative;
    border-radius: 10px;
    background: rgba(183, 227, 10, 0.1);
    border: 1px solid #1cbaed;
    color: #d8f664;
    margin-left: auto;
    padding: 8px 180px 8px 30px;
}
.verified-contract img {
    height: 40px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}
.verified-contract .btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}
.btn.small {
    padding: 5px 20px;
    font-size: 12px;
}
.admin-title > a {
    margin-right: 15px;
    position: relative;
    padding-left: 50px;
}
.admin-title .btn-green {
    margin-left: auto;
}
.admin-title > a img {
    max-width: 24px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}
.left-user {
    padding: 20px;
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}
.left-user img {
    width: 100%;
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 30px;
}
.project-stats {
    padding: 15px;
    margin-top: 10px !important;
    margin: 0 20px;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
}
.left-box > h3 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 12px;
    margin-top: 0px;
}
.left-box table {
    width: 100%;
}
.left-box table th,
.left-box table td {
    font-size: 12px;
}

@media (max-width: 1680px) {
    .btn.small {
        padding: 3px 14px;
        font-size: 10px;
    }
    .admin-title {
        padding: 15px 20px;
    }
    .r-link {
        font-size: 15px;
    }
    .link-title {
        font-size: 12px;
        white-space: nowrap;
        margin-right: 10px;
    }
    .card > .icon {
        width: 60px;
        height: 60px;
        line-height: 60px;
        top: calc(50% - 30px);
    }
    .card > .icon1{
        width: 60px;
        height: 60px;
        line-height: 60px;
        top: calc(50% - 30px);
    }    
    .card > .icon img {
        width: 34px;
    }
    .card > .icon1 img {
        width: 34px;
    }    
    .fig .btn.claim {
        bottom: -15px;
        right: -5px;
    }
    .btn.small {
        padding: 3px 14px;
        font-size: 10px;
    }
}

@media (max-width: 1278px) {
    
    .boxpadding {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width: 980px) {
    .link-box {
        display: block !important;
        text-align: center;
    }
    .trans-table {
        padding: 10px 0px;
    }
    .trans-table-div {
        overflow-x: auto;
        display: contents;
    }
    .dahsboard-sub-title {
        display: inline-flex;
        margin: 20px;
    }
    .plan-column {
        display: block;
        text-align: center;
    }
    .stake-icon {
        margin-left: auto !important;
        margin-right: auto !important;
        /* margin-bottom: 10px; */
    }
    .verified-contract {
        text-align: left;
        margin-top: 20px;
        padding-left: 50px;
        padding-right: 150px;
    }
    .verified-contract img {
        left: 10px;
    }
    .admin-title {
        display: block !important;
        text-align: center;
    }
    .ref-container img {
        right: unset !important;
        width: 90%;
    }
    .footer-logos {
        margin: 10px auto;
        display: flex;
    }
    .staking-field {
        width: unset;
    }
    .staking-head {
        display: block !important;
        /* padding-left: 10px !important; */
    }
    .admin-title > a {
        margin: 0;
        width: 100%;
        margin-bottom: 7px;
        padding-left: 15px;
    }
}
/*admin footer*/
.admin-footer {
    padding: 25px 40px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}
.copyright-admin {
    text-align: center;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.75);
}
.footer-logos {
    margin-left: auto;
}
.footer-logos a img {
    height: 40px;
}
::-webkit-scrollbar {
    width: 6px;
    height: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}
.lockIcon {
    width: 24% !important;
    height: 24% !important;
}
.MuiDrawer-root .MuiPaper-root {
    background-color: #000;
}
.this {
    display: block !important;
}
.connect-button {
    width: 260px;
    border: 0;
    background: #1cbaed !important;
    display: flex;
    justify-content: center;
    font-family: var(--bs-font-sans-serifar);
    font-weight: 700;
    color: #fff !important;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 0;
    padding: 14px 25px;
    font-size: 14px;
    border-radius: 50px;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.connect-button:hover {
    background: #119dcc !important;
}
.connect-button p {
    font-family: var(--bs-font-sans-serifar);;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}
.st {
    display: flex;
    justify-content: space-between;
    height: 28px;
}
