.tab-links {
    margin-bottom: 30px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    overflow: hidden;
    list-style: none;
    padding: 0px;
    cursor: pointer;
}
.tab-links > li {
    float: left;
}
.tab-links > li > a {
    display: block;
    font-family: var(--bs-font-sans-serifar);
    text-align: center;
    text-decoration: none;
    padding: 14px 25px;
    font-size: 14px;
    border-radius: 50px;
}
.tab-links > li.active a {
    background: #1cbaed;
    color: #2f2f2f;
}
.tab.active {
    display: block;
}

.tab-content > .active {
    display: block;
}
.banner-wrapper {
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 30px;
}
.banner-header {
    padding: 15px;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}
.banner-header span {
    font-family: var(--bs-font-sans-serifa);
    font-weight: normal;
    font-size: 36px;
    margin-bottom: 10px;
    background: #ffab5b;
    background: #65d013;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.banner-body {
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
}

.banner-body img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}
.banner-footer {
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}
@media (max-width: 580px) {
    .banner-footer .link-box {
        display: block!important;
    }    
}
.link-box {
    border-radius: 10px;
    /* background: rgba(183, 227, 10, 0.1); */
    border: 1px solid #1cbaed;
    color: #d8f664;
    overflow: hidden;
}

.align-items-center {
    align-items: center !important;
}
.d-flex {
    display: flex !important;
}

.link-title {
    padding: 10px 15px;
    background: #1cbaed;
    color: #fff;
    font-weight: 500;
    margin-right: 20px;
    border-radius: 0 10px 10px 0;
}
code.r-link {
    color: #fff;
    font-size: 13px;
}
.tab-enter {
    opacity: 0;
    transform: translateY(100%);
}

.tab-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 300ms, transform 300ms;
}

.tab-exit {
    opacity: 1;
    transform: translateY(0%);
}

.tab-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 300ms, transform 300ms;
}
@media (max-width: 1680px) {
    .link-title {
        font-size: 12px;
        white-space: nowrap;
        margin-right: 10px;
    }
    .r-link {
        font-size: 15px;
    }
    .btn.small {
        padding: 3px 14px;
        font-size: 10px;
    }
}
@media (max-width: 760px) {
    .link-title {
        margin-right: 0 !important;
        margin-bottom: 5px !important;
    }
    .level-list > li {
        float: unset !important;
        width: unset !important;
    }
    .tab-links {
        width: 100%;
        border-radius: 10px;
    }
    .tab-links > li {
        float: none;
    }
}
.logo-admin img {
    height: 70px;
    width: 100%;
}
.logo-admin {
    /* display: none !important; */
}
@media (max-width: 991.98px) {
    .logo-admin {
        /* background-color: #1cbaed; */
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 0px 10px;
    }
}
