.dapp-sidebar {
    height: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    background-color: #000;

    .branding-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin: 20px 0 0 0;

        .wallet-link {
            margin: 10px;

            p {
                font-family: Montserrat SemiBold;
                font-size: 14px;
                color: #ffffff;
            }
        }
    }

    .MuiLink-underlineHover:hover {
        text-decoration: none;
    }

    .dapp-menu-links {
        .dapp-nav {
            flex-flow: column;
            margin: auto;
            width: 85%;

            a {
                margin-left: 20px;
                align-items: center;
                margin-bottom: 20px;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    vertical-align: -4px;
                }
            }

            .button-dapp-menu {
                .dapp-menu-item {
                    align-items: center;
                    display: flex;
                    background: #121212;
                    text-decoration: none !important;
                    font-size: 18px;
                    padding: 15px;
                    color: #fff;
                    font-family: var(--bs-font-sans-serifa);
                    border-radius: 6px;
                    transition: 0.4s all;
                    -webkit-transition: 0.4s all;

                    p {
                        font-weight: 400;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }
                .dapp-menu-item:hover {
                    background-color: #fff;
                    text-decoration: none !important;
                    color: #121212;

                    p {
                        color: #121212;
                    }
                }
            }

            .bond-discounts {
                text-align: left;
                padding-left: 54px;

                a {
                    margin-left: 0;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                p {
                    font-family: Montserrat;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgba(255, 255, 255, 0.6);
                }

                .bond {
                    display: block;
                    padding: unset;
                    margin-bottom: 0 0 1px 0;
                    text-decoration: none !important;
                    .bond-pair-roi {
                        float: right;
                        margin-left: 33px;
                    }

                    .deprecated {
                        color: rgba(255, 255, 255) !important;
                        text-decoration: line-through;
                        p {
                            color: rgba(255, 255, 255) !important;
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }
    }

    .dapp-menu-doc-link {
        margin-top: auto;

        a {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                vertical-align: middle;
            }

            p {
                font-family: Montserrat SemiBold;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
            }
        }
    }
    .admin-social {
        padding: 0;
        text-align: center;
        list-style: none;
    }
    .admin-social li {
        display: inline-block;
        margin: 0 5px;
    }
    .admin-social li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 100%;
        display: block;
        font-size: 18px;
        color: #2f2f2f;
        background: #fff;
        transition: 0.1s all;
        -webkit-transition: 0.1s all;
        -moz-transition: 0.1s all;
    }
    .social-row {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        padding: 1.3rem;
        position: absolute;
        bottom: 0;
        margin: auto;
        width: 100%;
        a {
            &:hover {
                transform: scale(1.1);
            }
            img {
                height: 20px;
                width: 20px;
            }
        }
    }
}

.MuiDrawer-root {
    .MuiPaper-root {
        z-index: 7;
        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba(36, 39, 41, 0.1);
            backdrop-filter: blur(40px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }
    }
}
