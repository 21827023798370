@font-face {
    font-family: "Montserrat SemiBold";
    src: url("https://wonderland.fra1.digitaloceanspaces.com/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: url("https://wonderland.fra1.digitaloceanspaces.com/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Bold";
    src: url("https://wonderland.fra1.digitaloceanspaces.com/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Medium";
    src: url("https://wonderland.fra1.digitaloceanspaces.com/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Light";
    src: url("https://wonderland.fra1.digitaloceanspaces.com/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Courier";
    src: url("https://wonderland.fra1.digitaloceanspaces.com/CourierPrime-Regular.ttf") format("truetype");
}


@font-face {
	font-family: 'Age';
	src: url('./fonts/Age.woff2') format('woff2'),  url('./fonts/Age.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Archive';
	src: url('./fonts/Archive.woff2') format('woff2'),  url('./fonts/Archive.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
