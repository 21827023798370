.filter-wrapper {
    margin-bottom: 30px;
}

.main-wrapper {
    padding: 40px;
    min-height: calc(100vh - 175px);
}
.filter-list {
    position: relative;
    list-style: none;
    padding: 1px 21px;
}
.filter-list > li {
    float: left;
    margin: 10px;
}

.filter-box {
    position: relative;
}
.filter-box input {
    display: none;
}
label {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
}
.filter-box label {
    font-size: 13px;
    line-height: 20px;
    padding: 10px 15px 10px 40px;
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 4px;
}
.filter-box input:checked + label {
    background: rgba(183, 227, 10, 0.2);
    border: 1px solid #1cbaed;
    color: #d8f664;
}
.filter-box label span {
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 100%;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}
.filter-box input:checked + label span {
    border-color: #1cbaed;
    text-align: center;
    font-size: 9px;
    line-height: 16px;
}
.filter-box input:checked + label span:after {
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
}

.trans-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}
.trans-table thead tr {
    background-color: #1cbaed;
}

.trans-table tr {
    border-radius: 4px;
    position: relative;
}
.trans-table td,
.trans-table th {
    padding: 10px;
}

.trans-table th {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
}
.trans-table tbody tr {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
}

.trans-table tr {
    border-radius: 4px;
    position: relative;
}
.trans-table tbody tr td {
    font-size: 14px;
}

.trans-table td,
.trans-table th {
    padding: 10px;
}
a {
    color: #fff;
    text-decoration: none;
}
.trans-table tbody tr td a {
    white-space: nowrap;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
}
.trans-table tbody tr td.green {
    font-weight: 500;
    color: #4ade80;
}

.trans-table tbody tr td {
    font-size: 14px;
}
.trans-table tbody tr td.red {
    color: #ff7355;
}
tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.trans-table-div {
    padding: 20px;
}
@media (max-width: 1680px) {
    .main-wrapper {
        padding: 20px;
    }
}
