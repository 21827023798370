.ref-viewNew {
    position: relative;
    z-index: 1;
    padding: 2%;
    padding-top: 3%;
    min-height: calc(100vh - 175px);
}
.refera-title {
    margin-top: 10px;
    margin-bottom: 60px;
    justify-content: space-between;
}
.your-level {
    font-weight: 700;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    padding: 10px 15px;
    border-radius: 4px;
}
.your-level span {
    color: #ffab5b;
    font-weight: 700;
    margin-left: 10px;
}
ol.steps {
    display: flex;
    list-style: none;
    text-align: center;
    counter-reset: milestones;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
}
ol.steps li {
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    font-size: 13px;
    line-height: 1.4;
}
ol.steps a,
ol.steps a:visited {
    color: #9ca09f;
}
ol.steps a {
    display: block;
    text-decoration: none;
}
ol.steps a::before {
    color: #2f2f2f;
    background-color: #1cbaed;
}

ol.steps a::before {
    border-radius: 50%;
    text-decoration: none;
    content: counter(milestones);
    counter-increment: milestones;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto 0.5em;
}
ol.steps a::before,
ol.steps a::after {
    display: block;
    font-size: 18px;
    box-sizing: border-box;
    font-family: var(--bs-font-sans-serifar);
}

ol.steps a::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 0;
    border: 0;
    left: 50%;
    top: 25px;
    border-bottom-width: 6px;
    margin-top: -0.25em;
}
ol.steps a::after {
    border-bottom-style: solid;
    border-color: #1cbaed;
}
ol.steps a::before,
ol.steps a::after {
    display: block;
    font-size: 18px;
    box-sizing: border-box;
    font-family: var(--bs-font-sans-serifar);
}
ol.steps li.current a::after {
    border: none;
    display: block;
    background: -webkit-linear-gradient(135deg, #1cbaed 25%, #d8f664 25%, #d8f664 50%, #1cbaed 50%, #1cbaed 75%, #d8f664 75%) !important;
    /* background: -moz-linear-gradient(135deg, #1cbaed 25%, #d8f664 25%, #d8f664 50%, #1cbaed 50%, #1cbaed 75%, #d8f664 75%) !important;
    background: -o-linear-gradient(135deg, #1cbaed 25%, #d8f664 25%, #d8f664 50%, #1cbaed 50%, #1cbaed 75%, #d8f664 75%) !important; */
    width: 100%;
    height: 6px;
    background-size: 25px 25px;
    -webkit-animation: bar-animation 3s linear infinite !important;
}
@keyframes bar-animation {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 50px 50px;
    }
}
ol.steps li.current a::before {
    color: #000;
    background-color: #fff;
    box-shadow: inset 0 0 0 0.375em #1cbaed;
}
ol.steps li.current ~ li a::before {
    color: #707070;
    background-color: #e7e7e3;
    box-shadow: inset 0 0 0 0.375em #d4d4d4;
}
ol.steps li.current ~ li a::after {
    border-bottom-style: none;
}
.level-list {
    margin-bottom: 40px;
    min-height: calc(100vh - 220px);
}

.level-list {
    list-style: none;
    position: relative;
    margin-left: -7px;
    margin-right: -7px;
    color: #fff;
    padding: 0px;
}
.level-list > li {
    float: left;
    width: 20%;
    padding: 0 7px;
    margin: 7px 0;
}
.level-box {
    position: relative;
}
.level-box {
    padding: 20px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}
.level-box h3 {
    font-family: var(--bs-font-sans-serifa);
    margin: 7px;
    font-weight: normal;
    font-size: 50px;
    margin-bottom: 5px;
    background: #ffab5b;
    background: #65d013;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.level-box h3 span {
    font-size: 24px;
    margin-left: 3px;
}
.level-box h3 b {
    font-weight: 400;
    font-family: var(--bs-font-sans-serifar);
    font-size: 18px;
    margin-left: 5px;
}
.turnover b {
    font-weight: 400;
    color: #6cebcd;
}
.level {
    display: inline-block;
    background: rgba(183, 227, 10, 0.2);
    border: 1px solid #1cbaed;
    border-radius: 3px;
    font-size: 11px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0 15px;
    font-family: var(--bs-font-sans-serifar);
    color: #d8f664;
    margin-top: 15px;
}
.referal-no {
    font-size: 13px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    line-height: 24px;
    padding: 0 12px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}
.ref-status {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 12px;
}
.finished-badge {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid rgba(108, 235, 207, 0.2);
    background: rgba(108, 235, 207, 0.1);
    color: rgba(108, 235, 207, 1);
}
.locked-badge {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 225, 0.1);
    color: rgba(255, 255, 255, 0.75);
}
.level-box.active {
    background: rgba(183, 227, 10, 0.1);
}
.level-box.locked {
    background: rgba(0, 0, 0, 0.2);
}
.link-box .btn {
    margin-left: auto;
    margin-right: 15px;
}
.filter-box input:checked + label span:after {
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
}
